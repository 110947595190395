import React, { Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "../../public/reviewer/assets/css/reviewer-public.css"
import "../css/@wordpress/block-library/build-style/theme.css"

const ScoreCard = ({ filterImage = "", title = "", overallScore = 0 }) => {
  const img = getImage(filterImage?.node?.childImageSharp)

  return (
    <Fragment>
      <div className="thumbnail position-relative">
        {img ? (
          <GatsbyImage image={img} alt="Test" placeholder="blurred" />
        ) : null}
        <span className="overall-score">
          {overallScore.toFixed(1)} <small>/ 10</small>
          <small className="d-block">FilterStudy Score</small>
        </span>
      </div>
    </Fragment>
  )
}

export default ScoreCard
