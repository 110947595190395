import React from "react"
import { ListGroup } from "react-bootstrap"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Footer = () => {
  return (
    <footer className="pt-3 pb-3 footer">
      <Container>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Row>
              <Col xs={12} lg={4}>
                <Link to={"/filterstudy-reviews"}>
                  <p>Air Filter Reviews</p>
                </Link>
              </Col>
              <Col xs={12} lg={4}>
                <Link to={"/about"}>
                  <p>Filterstudy Score Explained</p>
                </Link>
              </Col>
              <Col xs={12} lg={4}>
                <Link to={"privacy-policy"}>
                  <p>Privacy Policy</p>
                </Link>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="mt-2">© 2024 | FilterStudy</ListGroup.Item>
        </ListGroup>
      </Container>
    </footer>
  )
}

export default Footer
