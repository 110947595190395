import React, { useState, useRef, Fragment, useEffect } from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { Navbar, Nav, Container, Button, ListGroup } from "react-bootstrap"
import useMediaQuery from "../hooks/useMediaQuery"

const Search = ({ allPosts }) => {
  const [allPostsLinks, setAllPostsLinks] = useState([])
  const [displaySearchbar, setDisplaySearchbar] = useState(false)
  const [filteredPosts, setFilteredPosts] = useState([])

  const toggleSearchbar = () => {
    if (displaySearchbar) {
      setDisplaySearchbar(false)
    } else {
      setDisplaySearchbar(true)
    }
  }

  const searchByKeyword = (input = "") => {
    const filteredLinks = allPostsLinks
      .filter(link =>
        link["title"].toString().toLowerCase().includes(input.toLowerCase())
      )
      .slice(0, 5)

    if (filteredLinks.length > 0) {
      setFilteredPosts(filteredLinks)
    } else {
      setFilteredPosts([])
    }
  }

  useEffect(() => {
    if (allPosts?.length > 0) {
      setAllPostsLinks(allPosts)
    }
  }, [allPosts])

  return (
    <div className="flex flex-col items-center justify-center search relative">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="text-white m-auto"
          onClick={toggleSearchbar}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </div>

      {displaySearchbar ? (
        <div
          className="search__popup"
          onMouseLeave={() => {
            setDisplaySearchbar(false)
          }}
          onBlur={() => {
            setDisplaySearchbar(false)
          }}
        >
          <div className="search__box">
            <input
              id="search__input"
              placeholder="Search Filter Study"
              onInput={e => {
                if (e.target.value?.length > 0) {
                  searchByKeyword(e.target.value)
                }
              }}
            />
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="text-white m-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>

          {filteredPosts?.length > 0 ? (
            <div className="search__results">
              <ListGroup>
                {filteredPosts.map(item => (
                  <ListGroup.Item key={uuidv4()}>
                    <Link to={item["link"]}>{item["title"]}</Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="text-white menu"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
    </svg>
  )
}

const CloseMenu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="text-white menu"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  )
}

const Header = ({ data, allPosts = [] }) => {
  const [hamIconClicked, setHamIconClicked] = useState(false)

  const isSmallDevices = useMediaQuery("(max-width: 768px)")

  const pageLinks = useStaticQuery(graphql`
    query GetPages {
      allWpPage {
        edges {
          node {
            id
            link
            title
          }
        }
      }
    }
  `)

  return (
    <header className="global-header">
      <Navbar expand="lg" collapseOnSelect className="p-0 w-100">
        <div className="w-100" style={{ display: "grid" }}>
          <div className="global-header__top">
            <Container className="d-flex ">
              <div className="d-flex items-center justify-start  align-items-center">
                {isSmallDevices && hamIconClicked ? (
                  <span
                    onClick={() => {
                      setHamIconClicked(false)
                    }}
                  >
                    <CloseMenu />
                  </span>
                ) : isSmallDevices ? (
                  <span
                    onClick={() => {
                      setHamIconClicked(true)
                    }}
                  >
                    <Menu />
                  </span>
                ) : null}
                <Navbar.Brand href="#home">
                  <Link to="/">
                    <div className="d-flex flex-row gap-6 text-uppercase text-white logo">
                      <span>Filter</span>
                      <span style={{ fontSize: ".75rem" }}>★</span>
                      <span>Study</span>
                    </div>
                  </Link>
                </Navbar.Brand>
                {!isSmallDevices ? (
                  <span
                    className="text-white text-lowercase"
                    style={{ fontWeight: "100" }}
                  >
                    Air Filters Analyzed
                  </span>
                ) : null}
              </div>

              <Search allPosts={allPosts} />
            </Container>
          </div>

          {!isSmallDevices ? (
            <div className="global-header__bottom">
              <Container className="d-flex flex-col justify-content-start items-center h-100">
                <Nav>
                  <Link href={"/"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      style={{ width: "2rem", height: "1.5rem" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>
                  </Link>
                  {pageLinks?.allWpPage?.edges.map(link => {
                    return (
                      <Link
                        key={link?.["node"]["id"]}
                        to={link?.["node"]["link"]}
                        className="mr-3"
                      >
                        {link?.["node"]["title"]}
                      </Link>
                    )
                  })}
                </Nav>
              </Container>
            </div>
          ) : null}

          {isSmallDevices && hamIconClicked ? (
            <div className="global-header__bottom">
              <Link href={"/"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{ width: "2rem", height: "1.5rem" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </Link>
              {pageLinks?.allWpPage?.edges.map(link => {
                return (
                  <Link
                    key={link?.["node"]["id"]}
                    to={link?.["node"]["link"]}
                    className="mr-3"
                  >
                    {link?.["node"]["title"]}
                  </Link>
                )
              })}
            </div>
          ) : null}
        </div>
      </Navbar>
    </header>
  )
}

export default Header
