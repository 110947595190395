import React, { Fragment, useState, useEffect, useCallback } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import Header from "../components/header"
import Footer from "../components/footer"
import Banner from "../components/banner"
import { Container, Row, Col, Card, ListGroup, Form } from "react-bootstrap"
import ScoreCard from "../components/scoreCard"
import "bootstrap/dist/css/bootstrap.min.css"

const RecentlyTested = React.memo(({ topReviews = [] }) => {
  return (
    <Card className="mb-3">
      <Banner>
        <span className="text-white text-uppercase">Recently Tested</span>
      </Banner>
      <Card.Body className="p-0">
        <ListGroup variant="flush">
          {topReviews.map(elem => (
            <ListGroup.Item key={uuidv4()}>
              <Link to={`${elem["link"]}`}>
                <ScoreCard
                  filterImage={elem?.["reviewImage"]}
                  overallScore={elem["overAllScore"]}
                />
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  )
})

const Layout = ({ isHomePage, children }) => {
  const { allWpPost, allFile } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }

      allWpPost {
        edges {
          node {
            id
            title
            link
            review
          }
        }
      }

      allFile {
        images: edges {
          node {
            id
            url
            childImageSharp {
              fixed(grayscale: true) {
                base64
                tracedSVG
                aspectRatio
                srcWebp
                srcSetWebp
                originalName
              }
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const [pageLinks, setPageLinks] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [topReviews, setTopReviews] = useState([])

  useEffect(() => {
    if (Object.keys(allWpPost?.["edges"]).length > 0) {
      setPageLinks(
        allWpPost?.["edges"].map(edge => {
          return {
            link: edge?.["node"]?.["link"],
            title: edge?.["node"]?.["title"],
            id: edge?.["node"]?.["id"],
          }
        })
      )

      let sortedReviews = allWpPost?.["edges"]
        .map(edge => {
          const parsedData = JSON.parse(edge?.["node"]?.["review"])
          return {
            link: edge?.["node"]?.["link"],
            title: edge?.["node"]?.["title"],
            id: edge?.["node"]?.["id"],
            overAllScore: parsedData?.["review_overall_score"],
            reviewImage: allFile?.images.filter(image => {
              const regex = /(.*)\/uploads/gm

              if (parsedData?.["review_image"] && image["node"]["url"]) {
                return parsedData["review_image"].includes(
                  image?.["node"]?.["url"].replace(regex, "")
                )
              } else {
                return false
              }
            })?.[0],
          }
        })
        .filter(review => review["overAllScore"])
        .sort((a, b) => a["overAllScore"] - b["overAllScore"])
        .reverse()
        .slice(0, 4)

      setTopReviews(sortedReviews)
    }
  }, [allWpPost, allFile])

  // const handleSearchInput = useCallback(
  //   e => {
  //     if (e.target.value.length > 0) {
  //       const filteredLinks = pageLinks
  //         .filter(link =>
  //           link["title"]
  //             .toString()
  //             .toLowerCase()
  //             .includes(e.target.value.toLowerCase())
  //         )
  //         .slice(0, 5)

  //       if (filteredLinks.length > 0) {
  //         setSearchResults(filteredLinks)
  //       }
  //     }
  //   },
  //   [pageLinks]
  // )

  // const handleSearchBlur = e => {
  //   setTimeout(() => {
  //     setSearchResults([])
  //   }, 500)
  // }

  return (
    <Fragment>
      <div
        className="global-wrapper"
        data-is-root-path={isHomePage}
        style={{ minHeight: "900px" }}
      >
        <Header title="Test" isHomePage={true} allPosts={pageLinks}></Header>
        <main>
          <Container className="d-flex flex-wrap">
            <section className="global-section w-100">{children}</section>

            {/* <aside>
              <Row>
                <Col sm={12}>
                  <Form.Group
                    className="mb-3 mt-3 position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      className="search pl-4 pr-4"
                      onInput={handleSearchInput}
                      onBlur={handleSearchBlur}
                    />
                    {searchResults.length > 0 ? (
                      <ListGroup className="search-results">
                        {searchResults.map(item => (
                          <ListGroup.Item key={uuidv4()}>
                            <Link to={item["link"]}>{item["title"]}</Link>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <RecentlyTested {...{ topReviews }} />
                </Col>
                <Col sm={12}>
                  <LatestPosts {...{ pageLinks }} />
                </Col>

                <Col sm={12}></Col> 
              </Row>
            </aside> */}
          </Container>
        </main>
        <Footer></Footer>
      </div>
    </Fragment>

    // https://www.erichowey.dev/writing/load-more-button-and-infinite-scroll-in-gatsby/
  )
}

export default React.memo(Layout)
